/* @font-face {
    font-family: 'ProductSansBold';
    src: local('ProductSansBold'), url('../assets/fonts/ProductSansBold.woff2') format('woff2');
} */
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Arabic:wght@100;200;300;400;500;600;700&family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@100;200;300;400;500;600;700&family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
/* @font-face {
    font-family: 'IBMPlexSansArabicBold';
    src: local('ProductSansBold'), url('../assets/fonts/IBMPlexSansArabic-Bold.ttf') format('ttf');
} */

/* @font-face {
    font-family: 'IBMPlexSansArabicRegular';
    src: url('../assets/fonts/IBMPlexSansArabic-Regular.ttf') format('ttf');
}

@font-face {
    font-family: 'IBMPlexSansArabicMedium';
    src: url('../assets/fonts/IBMPlexSansArabic-Medium.ttf') format('ttf');
}

@font-face {
    font-family: 'IBMPlexSansArabicLight';
    src: url('../assets/fonts/IBMPlexSansArabic-Light.ttf') format('ttf');
}

@font-face {
    font-family: 'IBMPlexSansArabicExtraLight';
    src: url('../assets/fonts/IBMPlexSansArabic-ExtraLight.ttf') format('ttf');
}

@font-face {
    font-family: 'IBMPlexSansArabicThin';
    src: url('../assets/fonts/IBMPlexSansArabic-Thin.ttf') format('ttf');
}

@font-face {
    font-family: 'IBMPlexSansArabicSemiBold';
    src: url('../assets/fonts/IBMPlexSansArabic-SemiBold.ttf') format('ttf');
} */

* {
    font-family: 'IBM Plex Sans Arabic', sans-serif !important;
    scroll-behavior: smooth;
}

/* @font-face {
    font-family: 'ProductSansRegular';
    src: local('ProductSansRegular'), url('../assets/fonts/ProductSansRegular.woff2') format('woff2');
}

@font-face {
    font-family: 'ProductSansItalic';
    src: local('ProductSansBold'), url('../assets/fonts/ProductSansItalic.woff2') format('woff2');
}

@font-face {
    font-family: 'ProductSansBoldItalic';
    src: local('ProductSansBoldItalic'), url('../assets/fonts/ProductSansBoldItalic.woff2') format('woff2');
} */



:root {
    --bs-blue-100: #2d3f8e;
    --bs-blue: #11143a;
    --bs-secondary: #677291;
    --bs-light: rgba(38, 38, 39, 0.03);
    --bs-light-700: #d8def0;
    --bs-dark: #1e2438;
    --bs-border: #d8def0;
    --bs-font-sans-serif: "Poppins", "Helvetica", Arial, sans-serif;
    --bs-first: #581CD3;

    --bs-second: #016400;
    --bs-shadow: rgba(255, 255, 255, 0.35)
}

.btn-primary {
    color: white !important;
    background: var(--bs-first) !important;
}

.btn-primary.icon {
    color: var(--bs-first) !important;
    background: transparent !important;

}

.bg-light {
    background: var(--bs-light) !important;
}

body {
    font-style: normal;
    font-weight: 400;
}

h2 {
    color: var(--bs-first);
    font-size: 32px;
    font-weight: bold;
    font-family: "ProductSansBold", serif;
}

a {
    color: var(--bs-second);
}

a:hover {
    color: var(--bs-first);
}

.text-primary {
    color: var(--bs-blue) !important;
}

.text-muted,
.text-secondary {
    color: var(--bs-secondary) !important;
}

.small,
small {
    font-size: .675em;
}

.height-unset {
    height: unset !important;
}

.btn,
.btn-outer {
    font-size: .75rem;
    line-height: 1.25rem;
    font-weight: 700;
    padding: .675rem 1rem;
    background-color: var(--bs-second);
    color: white;
}

.btn-outer {
    background-color: transparent !important;
    color: var(--bs-dark) !important;
    border: 1px var(--bs-border) solid !important;
    border-radius: 8px !important;
}

.width-none {
    width: unset !important;
}

.pd-0 {
    padding: unset;
}

.pd-h-4 {
    padding: 0 4px !important;
}

.pd-20 {
    padding: 20px !important;
}

.mt-1,
.mv-1 {
    margin-top: 1rem;
}

.mt-2,
.mv-2 {
    margin-top: 2rem;
}

.mb-1,
.mv-1 {
    margin-bottom: 1rem;

}

.mb-2,
.mv-2 {
    margin-bottom: 2rem;

}

.mt-0,
.mb-0 {
    margin: 0 !important;
}


.mb-24 {
    margin-bottom: 24px !important;
}

.mt-24 {
    margin-top: 24px !important;
}

.mt-12 {
    margin-top: 12px !important;
}

.mt-20 {
    margin-top: 20px !important;
}

.mb-12 {
    margin-bottom: 12px !important;
}

.mb-16 {
    margin-bottom: 16px !important;
}

.mb-8 {
    margin-bottom: 8px !important;
}

.mb-20 {
    margin-bottom: 20px !important;
}

.mt-30 {
    margin-top: 30px !important;
}

.mt-60 {
    margin-top: 60px !important;
}

.mb-40 {
    margin-bottom: 40px !important;
}

.mb-48 {
    margin-bottom: 48px !important;
}

.min-w100 {
    min-width: 100px !important;
}

.card-hover:hover {
    transform: scale(1.05);
}

.card-hover {
    transition: all ease 0.3s !important;
}

.btn.btn-primary {
    background-color: var(--bs-first) !important;
    border-radius: 8px;
    border: none;
    color: white;
}

.btn-primary:focus,
.btn-primary:hover {
    /*border-radius: .3rem;*/
    background: var(--bs-first);
    color: white;
}

.btn-primary.disabled,
.btn-primary:disabled {
    color: #767C84;
    background-color: #D8DBDE;
    border-color: #D8DBDE;
}


.btn-secondary {
    color: #fff;
    background-color: var(--bs-secondary);
    border-color: var(--bs-secondary);
}

.btn-secondary:focus,
.btn-secondary:hover {
    color: #fff;
    background-color: var(--bs-blue);
    border-color: var(--bs-blue);
}



.btn-sm {
    padding: 0.25rem 0.5rem;
    border-radius: 0.2rem;
    font-size: 0.7em;
}

.lang .form-select {
    font-size: .75rem;
    text-transform: uppercase;
    font-weight: 700;
    padding-right: .5rem;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='481.728px' height='481.728px' viewBox='0 0 481.728 481.728' style='enable-background:new 0 0 481.728 481.728;' xml:space='preserve' fill='%23221a44'%3E%3Cg%3E%3Cg%3E%3Cpath d='M10.467,146.592l198.857,252.903c17.417,30.532,45.661,30.532,63.085,0l198.839-252.867 c3.886-5.532,8.072-15.41,8.923-22.118c2.735-21.738,4.908-65.178-21.444-65.178H23.013c-26.353,0-24.192,43.415-21.463,65.147 C2.395,131.188,6.587,141.06,10.467,146.592z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
    -webkit-background-size: .5rem .5rem;
    background-size: .375rem .375rem;
    background-repeat: no-repeat;
    background-position: 100% 50%;
    cursor: pointer;
}

.btn {
    width: 136px;
    height: 48px;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    border-radius: 8px;
}

.btn-outline-primary {
    border-color: var(--bs-first);
    color: var(--bs-first);
    background-color: #fff;
}

.form-footer .btn-outline-primary {
    background-color: color-mix(in srgb, var(--bs-first) 6%, #fff);
}

.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary.active {
    color: #fff;
    background-color: var(--bs-first);
    border-color: var(--bs-first);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
    color: #778399;
    background-color: transparent;
    border-color: #778399;
}

.account-aside .btn-outline-primary {
    color: #475767;
    border-color: #E4E9EF;
    font-size: 18px;
    font-weight: 500;
    height: auto;
    min-height: 56px;
}

.account-aside .btn-outline-primary:hover,
.account-aside .btn-outline-primary:focus,
.account-aside .btn-outline-primary.active {
    color: #fff;
    background-color: var(--bs-first);
    border-color: var(--bs-first);
}


.nav-link {
    font-size: .75rem;
    text-transform: uppercase;
    font-weight: 500;
}

.card-footer .btn {
    max-width: 50%;
}

.card-header,
.card-footer {
    background: transparent;
    border: none;
}

.card {
    padding-top: 1rem;
    padding-bottom: 1rem;
    position: relative;
}

/* .card-hover .card-body,
.card-hover .card-body .list-unstyled.text-muted {
    min-height: 8rem;
} */
.list-style-none {
    list-style: none !important;
    padding-right: .5rem !important;

}

.list-style-none li {
    list-style: none !important;
    padding-right: 0rem !important;

}

.card-header {
    /*color: var(--bs-blue);*/
    color: #7E7E7E;
    font-family: "ProductSansRegular", serif;
}

.card-header h5 {
    font-size: 24px;
}

.bg-primary-opacity {
    background: var(--bs-blue);
}


.fw-600 {
    font-weight: 600;
}

.form-check-input {
    cursor: pointer;
}

.form-check-input:checked {
    background-color: var(--bs-first);
    border-color: var(--bs-blue);
}


.text-muted,
.text-secondary {
    color: var(--bs-secondary) !important;
}



/* Nav Sidebar */
.list-group-item {
    color: var(--bs-secondary);
    padding: 0;
    position: relative;
    margin: 0;
    font-size: .75rem;
    text-transform: uppercase;
    font-weight: 500;
}

.list-group-item span {
    margin-left: .5rem;
}

.list-group-item i {
    margin-right: .5rem;
}

.list-group-item+.list-group-item {
    margin-top: 1rem !important;
}

.list-group-item:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: -1.5rem;
    background: var(--bs-blue);
    width: 0;
    transition: all .2s;
}

.list-group-item:hover {
    color: var(--bs-blue);
}

.list-group-item.active:hover,
.list-group-item.active {
    color: var(--bs-blue) !important;
    background: transparent;
}

.list-group-item.active:hover {
    color: #0d6efd;
    background: transparent;
}

.list-group-item.active:before {
    width: 2px;
}

.list-group-item .badge {
    border-radius: 50%;
    padding: 0;
    width: .5rem;
    height: .5rem;
    line-height: 1rem;
    font-size: 10px;
    transform: translateY(-.5rem);
    font-size: 0;
}

/*switch button*/
.custom-switch-label::before,
.custom-switch-label::after {
    width: 20px;
    height: 20px
}

/* Nav pills */
.nav-pills .nav-link {
    padding: 0;
    color: var(--bs-secondary);
    display: inline-block;
    border-bottom: 1px solid transparent;
    border-radius: 0;
}

.nav-item+.nav-item {
    margin-left: 1.5rem;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: var(--bs-dark);
    border-color: var(--bs-blue);
    background: transparent;
}

.nav-link:focus,
.nav-link:hover {
    color: var(--bs-blue);
}





/* Form */
.form-label {
    font-size: .875rem;
    color: var(--bs-secondary);
    margin-bottom: .15rem;
}

.form-control:focus {
    box-shadow: none !important;
    border-color: var(--bs-first);
}



.form .btn-link {
    color: var(--bs-blue);
}

.form-select,
.form-control {
    color: var(--bs-dark);
    font-weight: 500;
    padding: 16px 20px;
    max-height: 54px;
}

.form-select {
    border: none;
}

.form-control {
    border: 1px solid #E4E9EF;
    border-radius: 8px;
}

.form-select input {
    height: 22px;
}

.lang .form-control:read-only {
    background-color: transparent;
}

.form-control:read-only:focus {
    border-bottom: 1px solid var(--bs-secondary);
}

.form-select:focus,
.form-control:focus {
    background-color: transparent;
    box-shadow: none !important;
}

.form-control:focus+div>i {
    color: var(--bs-blue);
}

.form-control+div>i {
    font-size: .875rem;
}

.valid-feedback,
.invalid-feedback {
    position: absolute;
    left: 0;
    top: 100%;
    font-size: 10px;
}

.form-group .end-0 {
    right: 0 !important;
}

.form-control.is-invalid+.position-absolute,
.form-control.is-valid+.position-absolute {
    display: none !important;
}


.form-select:focus,
.form-check-input:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.address p {
    font-size: .875rem;
}

.address-inner {
    transition: all .2s ease;
}

.link {
    font-weight: 500;
    text-decoration: underline;
}

.link:hover span {
    text-decoration: none;
}

button.link {
    border: none;
    background: transparent;
    padding: 0;
    font-weight: 600;
    font-size: .875rem;
    white-space: nowrap;
}

button.link:hover {
    text-decoration: none;
}

.btn .fa-spinner {
    animation: rotate 2s ease infinite;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

.fsz-md {
    font-size: .875rem;
}

.fs-20 {
    font-size: 20px !important;
}

.fs-24 {
    font-size: 24px !important;
}

.fs-16 {
    font-size: 16px !important;
}

.fs-17 {
    font-size: 17px !important;
}

.fs-18 {
    font-size: 18px !important;
}

.card {
    transition: all .2s ease-in;
}


.card .bg-light .form-check-label {
    white-space: nowrap;
    cursor: pointer;
}

.text-white .text-muted {
    color: rgba(255, 255, 255, .8) !important;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
    background-position: right calc(.375em + 1.25rem) center;
}

.form-control.is-invalid.extra-icon {
    background-position: right calc(.375em + 1.25rem) center;
}

input:-internal-autofill-selected:focus,
input:-internal-autofill-selected,
input:-webkit-autofill,
input:-webkit-autofill:focus {
    box-shadow: 0 0 0px 1000px rgba(255, 255, 255, .9) inset !important;
    -webkit-box-shadow: 0 0 0px 1000px rgba(255, 255, 255, .9) inset !important;
}

.btn:focus,
.form-control:focus {
    box-shadow: none !important;
}

[dir="rtl"] .nav-item,
[dir="rtl"] .nav-item+.nav-item {
    margin-left: 1.5rem;
}

[dir="rtl"] .form-group .position-absolute.end-0 {
    right: auto !important;
    left: 0 !important;
    padding-left: 0;
}

[dir="rtl"] .form-text.me-2 {
    margin-right: .5rem !important;
    margin-left: 0 !important;
}

.card-inputs-block .card-number-frame,
.card-inputs-block .expiry-date-frame,
.card-inputs-block .cvv-frame {
    height: 50px;
}

.cursor-pointer {
    cursor: pointer;
}

/* FOOTER*/


.navbar {
    max-height: 90px;
    padding-top: 20px;
    padding-bottom: 20px;
    background: #fff !important;
    /* background: #fdfeff !important; */
    border-bottom: 2px solid #E4E9EF;
    /* border-top: 3px solid var(--bs-first); */
    /* background: url('../assets/images/nav_background.png') !important; */
}

.navbar-light .navbar-nav .nav-link {
    font-size: 16px;
    color: #394456;
}

.navbar-light .navbar-nav .nav-link.active {
    color: var(--bs-first);
}

.navbar-light .navbar-nav .btn-outline-primary .nav-link,
.navbar-light .navbar-nav .btn-outline-primary .nav-link:hover {
    color: inherit !important;
}

.navbar-light .navbar-nav .nav-link:hover {
    color: var(--bs-first) !important;
}


.banner-block {
    position: relative;
    /* overflow: hidden; */
    /* height: 650px; */
    text-align: center;
}

.banner-block img {
    /*!*display: block;*!*/
    /*position: absolute;*/
    /*z-index: 1;*/
    /*top: 50%;*/
    /*left: 50%;*/
    /*transform: translate(-50%, -50%);*/
    max-width: 100%;
    text-align: center;
}

.banner-url-block {
    position: absolute;
    width: 100%;
    z-index: 3;
    text-align: center;
    padding-top: 1rem;
}

.banner-url-block .btn {
    padding: 27px 70px;
    font-size: 20px;
}

.navbar-brand {
    padding: 0;
}

.navbar-brand img {
    max-height: 90px;
    max-width: 140px;
}

.form-container {
    padding: 48px 60px;
    border-radius: 2px;
}

.form-container h1 {
    font-size: 32px;
    font-weight: 600;
}

.form-container .under-header {
    font-size: 16px;
    font-weight: 400;
}

.form-container .form {
    margin-top: 32px;
}


.form-container .form-footer hr {
    width: 30%;
}

.form-container .form-footer .another-way {
    height: 80px;
}

.form-container .form-footer .btn-primary {
    height: 54px;
}

.account-content-container {
    border: 1px solid #E4E9EF;
    border-radius: 8px;
    padding: 16px 24px;
}

.account-content-container h2 {
    font-size: 20px;
    font-weight: 700;
    color: #394456;
}

.account-content-container hr {
    color: #E4E9EF;
    font-size: 1px;
}

.input-inside-icon {
    border-width: 0 1px;
    border-style: solid;
    border-color: #E4E9EF;
}

.account-content-container .form-control:disabled,
.account-content-container .form-control[readonly] {
    background-color: #F8F8F8;
}

.account-content-container input,
.account-content-container label {
    font-size: 16px;
    font-weight: 500;
    color: #394456;
}

.account-content-container .form-select {
    border: 1px solid #E4E9EF;
    border-radius: 8px;
}

.costs-block {
    border-radius: 16px !important;
}

.costs-block .text-dark,
.costs-block small {
    color: var(--bs-second) !important;
}

.block-plans .btn-primary {
    max-width: none;
    /*width: 336px;*/
    height: 71px;
    font-size: 20px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px 22px;


    background: var(--bs-first);
    border-radius: 48px;



    flex: none;
    order: 1;
    flex-grow: 0;
}

.block-plans h3 {
    font-family: 'ProductSansRegular', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 32px;
    letter-spacing: 0.01em;
    color: rgba(0, 0, 0, 0.4);
    padding-bottom: 59px;
}

.features-block li {
    text-align: initial;
    color: rgba(38, 38, 39, 0.4);
}

.features-block svg {
    padding: 2px;
    width: 23px !important;
    height: 23px;
    color: var(--bs-second) !important;
}

.m-icon {
    width: 24px;
    height: 24px;
    margin: 0 0 0 11px;
}

.l-icon {
    width: 27px;
    height: 27px;
    margin: 0 0 0 11px;
}

.f-soc-row {
    padding-top: 16px;
    justify-content: flex-end;
}

.plan_img {
    max-width: 100%;
    overflow: hidden;

    /* background-size: auto; */
    /* background-size: cover; */
    max-height: 100%;
}



/*=========================================================
	FOOTER
===========================================================*/
footer {
    height: 554px;
    /* background: url('../assets/images/FrameTest.png'), linear-gradient(180deg, var(--bs-first) 0%, var(--bs-second) 32.9%); */
    background-image: linear-gradient(180deg, var(--bs-first) 0%, var(--bs-second) 32.9%);

}


.footer-top-line {
    height: 36px;
    /*background: url('../assets/images/footer_figures_brighter.png'), linear-gradient(180deg, #018400 0%, var(--bs-second) 32.9%);*/
}

.footer-info {
    /* height: 383px; */
    /* padding-top: 118px; */
    color: white;
    font-size: 21px;
}




.footer-long-text {
    /* width: 355px; */
    color: #394456;
    font-size: 16px;
    font-weight: 400;
}

.footer-copyright {
    height: 169px;
    border-top: var(--bs-first) 2px solid;
    color: var(--bs-first);
    font-size: 20px;
    padding-top: 41px;
}

.footer-key {
    /* width: 135px; */
    color: #394456;
}

.footer-key p {
    font-size: 16px;
    font-weight: 400;
}

.footer-value {
    /* color: var(--bs-first); */
    color: #394456;
    font-size: 18px;
}



#footer {
    background: #F5F7F9;
    color: #b9babc;
    height: auto;
}

#bottom-footer {
    background: #1e1f29;
    box-shadow: 0px -3px 10px -1px var(--bs-shadow);
}

.footer2 {
    margin: 30px 0px;
}

.footer2 .footer-title {
    color: #fff;
    text-transform: uppercase;
    font-size: 18px;
    margin: 0px 0px 30px;
}

.footer-links li+li {
    margin-top: 15px;
}

.footer-links li a {
    color: #b9babc;
}

.footer-links li a:hover {
    color: #d10024;
}

.copyright {
    margin-top: 30px;
    color: var(--bs-first);
    font-size: 1em;
    font-style: italic;
    text-decoration: underline;
}

#footer img {
    max-width: 140px;
}


.footer-payments .social-icon {
    width: 28px;
    height: 28px;
    color: #394456;
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.08));
    background-color: #fff;
    border-radius: 5px;
}

.footer-payments .social-icon i {
    position: relative;
    top: calc(50% - 10px);
}

.footer-payments a {
    color: #394456;
    font-size: 18px;
    font-weight: 400;
    display: block;
    transition: all ease 0.3s;
    text-decoration: none;

}

#footer .footer-title {
    font-size: 20px;
    font-weight: 600;
    color: #171822;
}

.footer-icon:hover {
    color: var(--bs-first);
}

.section {
    padding-top: 30px;
    padding-bottom: 30px;
}

#footer ul,
#footer ol {
    margin: 0;
    padding: 0;
    list-style: none;
}

#powered-footer {
    border-top: 1px solid #E4E9EF;

}

#powered-footer img {
    width: 25px;
}

#powered-footer a {
    color: #394456;
    font-size: 14px;
    font-weight: 500;
    text-decoration: None;
}

.resend-span {
    color: var(--bs-first);
}

.country-phone .react-select__indicators {
    display: none;
}

.country-phone .form-select {
    border-bottom: none;
    width: 130px;
    padding: 0;
    background-position: right 0.75rem center !important;
}

.country-phone:focus-within {
    border-color: var(--bs-first);
}


.country-phone {
    border: 1px solid #E4E9EF;
    border-radius: 8px;
    padding: 16px 20px;
    max-height: 54px;
}

.country-phone .form-control.is-invalid {
    background-position: right calc(0.375em + 0rem) center;
}


/* components */
/* start close */
.close-parent {
    position: relative
}

.close {
    position: absolute;
    top: 0;
    left: 0
}

.close-text {
    color: var(--bs-first);
    font-size: 16px;
}

.divider {
    border: none;
    height: 2px;
    background-color: #e4e9ef8a;
}

/* end close */
/* end components */


/* general style new UI */
.color-dark {

    color: var(--bs-dark) !important;
}

.ui-card,
.ui-card-outer {
    border: 1px solid transparent;
    padding: 24px;
    border-radius: 12px;
    background: #e4e9ef8a;
}

.ui-card-outer {
    border: 1px solid #e4e9efc9;
    background: none;
}

.transition-card {
    transition: all .3s ease-in-out;

}

.transition-card:hover {
    transform: scale(1.05);
}

.card-bg {
    background-color: #0d6efd;
}



.card-title,
.card-title-big,
.card-title-bigger {
    font-size: 20px;
    color: #171822
}

.font-20 {
    font-size: 20px !important;
}

.font-8 {
    font-size: 8px !important;
}

.card-title-bigger {

    font-size: 24px !important;
    font-weight: 700 !important;

}

.mr-8 {
    margin-right: 8px !important;
}

.mr-6 {
    margin-right: 6px !important;

}

.ml-6 {
    margin-left: 6px !important;

}

.ml-8 {
    margin-left: 8px !important;
}

.ml-26 {
    margin-left: 26px !important;

}

.color-text {
    color: #394456 !important;
}

.color-info {
    color: #767C84 !important;
}

.card-info,
.card-info-bolder {

    font-size: 20px;
    color: var(--bs-secondary)
}

.card-info-bolder {

    font-weight: 700;
}

.card-text {

    font-size: 16px;
    color: var(--bs-secondary);
    font-weight: 400;
    line-height: 28px;
}

.font-mid {
    font-size: 16px !important;
}

.color-primary {
    color: var(--bs-first)
}

.coupon-input-text {
    border-radius: 8px !important;
    background-color: #F6F7FB;
}

.coupon-input-text:focus {
    max-width: 75%;




}

.coupon-placeholder::placeholder {

    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px;


}

.coupon-form .btn {
    height: auto !important;
}

/* general style new UI */
.country-phone input {
    padding-top: 0;
    padding-bottom: 0;
    height: 22px;
}

.pipeline {
    border-left: 1px solid #E4E9EF;
    height: 22px;
}

.otp-input {
    width: calc(90% / 6);
    font-size: 32px;
    max-height: none;
    box-sizing: border-box;
    padding: 0px !important;
}


.otp-input:disabled {
    background-color: color-mix(in srgb, var(--bs-first) 6%, #fff);
    color: var(--bs-first);
}

.verify-form .is-invalid {
    padding: 16px 20px;
    background: none;
}

.text-first {
    color: var(--bs-first);
}

.empty-address {
    min-height: 400px;
    color: #394456;
}

.empty-address h3 {
    font-size: 18px;
    font-weight: 500;
}

.empty-address p {
    color: #767C84;
    font-size: 16px;
    font-weight: 400;
}

.address-container {
    background-color: #F6F7FB;
    border-radius: 8px;
}

.address-container .badge {
    background-color: #738195;
}

.address-container .key {
    color: #394456;
    font-size: 14px;
    font-weight: 500;
}

.address-container .value {
    color: #738195;
    font-size: 16px;
    font-weight: 400;
}

.address-container hr {
    color: #E4E9EF;
}

.address-container svg {
    color: #475767;
}


.breadcrumb-item.active {
    color: var(--bs-first);
}

.breadcrumb-item a {
    color: #767C84;
}

.search-input .form-control {
    width: 290px;
    padding: 12px 40px;
    max-height: 44px;
}

.search-input .form-select,
.language-container .form-select,
.pagination-container .form-select {
    padding-top: 5px;
}

.search-input .react-select__indicators,
.language-container .react-select__indicators,
.pagination-container .react-select__indicators {
    left: 0 !important;
    right: auto !important;
    padding: 3px 0 0 10px;
}

.search-input svg {
    color: #8C8FA7;
}

.subscriptions-table {
    border: 1px solid #E4E9EF;
    border-radius: 8px;
}

.subscriptions-table thead {
    background-color: #F6F7FB;
}

.subscriptions-table th {
    color: #394456;
    font-size: 16px;
    font-weight: 400;
    border-bottom: 1px solid #E4E9EF !important;
}

.subscriptions-table td {
    color: #475767;
    font-size: 16px;
    font-weight: 500;
}

.subscriptions-table table tbody tr td .btn-primary {
    background-color: color-mix(in srgb, var(--bs-first) 6%, #fff);
    border-color: var(--bs-first);
    color: var(--bs-first);
}


.subscriptions-table .last-row td,
.subscriptions-table .last-row th {
    border-bottom: none !important;
}



.pagination {
    margin-bottom: 0;
}

.pagination .page-item .page-link {
    color: #394456;
    font-size: 16px;
    font-weight: 500;
    border-radius: 8px;
    width: 32px;
    padding: 3px 0;
    text-align: center;

}

.pagination .page-item.active .page-link {
    background-color: var(--bs-first);
    border-color: var(--bs-first);
    color: #fff;
}

.pagination .page-item.disabled .page-link {
    background-color: #F6F7FB;
    border-color: #E4E9EF;
    color: #394456;
}

.pagination-container .form-select {
    max-width: 70px;
    max-height: 40px;
}

.pagination-container .react-select__indicators {
    padding-left: 4px;
}

.subscription-container {
    border: 1px solid #E4E9EF;
    border-radius: 8px;
    min-height: 300px;
}

.subscription-details-card {
    border-radius: 8px;
    background-color: #F6F7FB;
}

.subscription-details-card h2 {
    color: #394456;
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
}

.subscription-details-card h1 {
    color: #394456;
    font-size: 40px;
    font-weight: 700;
    line-height: 28px;
}

.subscription-details-card .plan-price {
    color: #394456;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
}

.subscription-details-card .plan-advs {
    color: #475767;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
}

.subscription-details-card .plan-status {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.subscription-details-card .plan-status.active,
td.active {
    color: #33A663;
}

.subscription-details-card .plan-status.cancelled,
td.cancelled {
    color: #FF0000;
}

.subscription-details-card .plan-status.warnning,
td.warnning {
    color: #ffc107;
}

.subscription-details-card .plan-option {
    color: #475767;
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
}


.subscription-details-card .btn-outline-primary.cancel-plan {
    color: #FB8477;
    border-color: #FB8477;
}

.subscription-details-card .btn-outline-primary.cancel-plan:hover {
    color: #fff;
    background-color: #FB8477;
    border-color: #FB8477;
}

.subscription-details-card .btn-outline-primary.cancel-plan:focus {
    color: #fff;
    background-color: #FB8477;
    border-color: #FB8477;
}

.subscription-details-card .btn-outline-primary.cancel-plan:active {
    color: #fff;
    background-color: #FB8477;
    border-color: #FB8477;
}

.subscription-details-card .btn-outline-primary.cancel-plan:disabled,
.subscription-details-card .btn-outline-primary.cancel-plan.disabled {
    color: #778399;
    background-color: transparent;
    border-color: #778399;
}

.invoices h3 {
    color: #394456;
    font-size: 20px;
    font-weight: 600;
    line-height: 23px;
}

.invoices .invoice-card {
    border: 1px solid #E4E9EF;
    border-radius: 8px;
}

.pdf-icon {
    border: 1px solid #E4E9EF;
    border-radius: 8px;
    color: #778399;
}

.invoice-name {
    color: #394456;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
}

.invoice-row {
    width: 50%;
}

.language-select {
    max-width: 350px
}

.language-container .form-select {
    padding-top: 12px;
}

.language-container p {
    color: #394456;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
}

.language-container span {
    color: #767C84;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.checkout-btn {
    min-height: 48px;
    height: auto;
}

@media only screen and (max-width:991px) {
    .navbar {
        max-height: none;
    }
}

@media only screen and (max-width: 981px) {
    .footer-info {
        padding-top: 30px;
        padding-bottom: 20px;
        font-size: 16px;
        height: auto;
    }

    .footer-copyright {
        height: 169px;
        border-top: #019300 2px solid;
        color: var(--bs-first);
        font-size: 16px;
        padding-top: 30px;
    }

    .footer-value {
        font-size: inherit;
    }
}

@media only screen and (max-width: 767.98px) {
    .nav-pills .nav-link {
        font-size: .875rem;
    }

    .nav-item+.nav-item {
        margin-left: .5rem;
    }

    .nav {
        flex-wrap: nowrap;
    }

    #footer .section {
        padding-left: 1.25rem;
        padding-right: 1.25rem
    }

    .invoice-row {
        width: 100%;
    }
}


@media only screen and (max-width: 575.98px) {
    .nav {
        justify-content: space-between;
    }

    .form-container {
        padding: 24px 30px;
    }

}

@media only screen and (max-width: 300px) {
    .footer-value {
        font-size: 14px;
    }

    .form-container {
        padding: 12px 15px;
    }

    .otp-input {
        font-size: 24px;
    }
}

.label-class {
    display: flex;
    align-items: center;

}

/**/
.feat-text {
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
    color: #475767;
}

.card-sub-title {

    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #394456;

}

.summery-item-title {

    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #475767;
}

/*  */
/* plans page */
.plans-title-text {

    font-style: normal;
    font-weight: 700;
    font-size: 44px;
    line-height: 64px;
    color: #323A46;


}

.plans-info-text {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #475767;
}

.plan-card {
    padding: 20px 16px;

}

.plan-card-title,
.main-title {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;


    color: #323A46;
}

.plan-card-info {

    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.1em;
    color: #394456;


}

.plan-card-price {
    padding: 12px;
    background: #F6F7FB;
    border-radius: 8px;

}

.plan-card-price-text {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 40px;
    color: #394456;
    margin-top: 0 !important;

    margin-bottom: 0 !important
}

.plan-card-price-text-span {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    color: #475767;
}

.plan-card-price.icon {
    width: 20px;
    height: 20px;
    padding: 4px;
    color: #581CD3
}

.plan-card-price .plan-card-cost {
    margin-bottom: 16px !important;
    padding: unset;
}

.plan-card-price .plan-card-cost:last-child {
    margin-bottom: 0px !important;
}

.plan-card-price .btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px 22px;


    background: #581CD3;
    border-radius: 48px;



    flex: none;
    order: 1;
    flex-grow: 0;
}

.plan-card .feature-plan-text {

    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    color: #475767;
}

/* plans page */
/* checkout */
.pay-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;

    border-radius: 8px;
    flex: none;
}

/* end checkout */



/* credit info */


.credit-title,
.min-title {
    display: flex;
    flex-direction: column;

    padding: 0px;
    gap: 16px;


    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height, or 133% */


    color: #394456;
}

.credit-label {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #394456;

}

.credit-label-div {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px 4px 0px 0px;
}

.checkout-frames-card-number {
    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
    gap: 16px;
    background: #FFFFFF;
    border: 1px solid #E4E9EF;
    border-radius: 4px;

    /* Inside auto layout */

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

/* end credit info */

/* model */
.body-model {
    background: #FFFFFF;
    border-radius: 8px;
    padding: 24px;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 30px;
    min-height: 400px;
    max-height: 60vh;
    overflow-y: scroll;
    scroll-behavior: smooth;
}

@media only screen and (max-width: 600px) {
    .body-model {
        margin-top: 20px;
        margin-bottom: 20px;
        min-height: 100vh;
        max-height: 100vh
    }


}

/* end model */



/* success  */
.success .btn-text {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #581CD3;
}

.success .btn,
.success .btn-outer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    gap: 10px;

    width: 100%;
    height: 48px;

    background: var(--bs-first);
    border-radius: 8px;
    color: white !important;

}

.success .btn-outer {
    background-color: unset !important;
    border-color: var(--bs-first) !important;
    color: var(--bs-first) !important;
}

/* success */

/* faqs */
.faqs .text-head {

    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #394456;
}

.faqs .text-head.active {
    font-weight: 600;
    color: #802BBE;
}

.faqs .text-body {

    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;

    color: #475767;

}

.faqs img {
    width: 24px;
    height: 24px;
    color: #581CD3;
}

.faqs img.close {
    color: #475767;

}



/* end faqs */