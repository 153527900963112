
.form-row {
    width: 70%;
    float: left;
    background-color: #ededed;
}

#card-element {
    background-color: transparent;
    height: 40px;
    border-radius: 4px;
    border: 1px solid transparent;
    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
}

#card-element--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
}

#card-element--invalid {
    border-color: #fa755a;
}

#card-element--webkit-autofill {
    background-color: #fefde5 !important;
}

#submitbutton, #tap-btn {
    align-items: flex-start;
    background-attachment: scroll;
    background-clip: border-box;
    background-color: rgb(50, 50, 93);
    background-image: none;
    background-origin: padding-box;
    background-position-x: 0%;
    background-position-y: 0%;
    background-size: auto;
    border-bottom-color: rgb(255, 255, 255);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-style: none;
    border-bottom-width: 0px;
    border-image-outset: 0px;
    border-image-repeat: stretch;
    border-image-slice: 100%;
    border-image-source: none;
    border-image-width: 1;
    border-left-color: rgb(255, 255, 255);
    border-left-style: none;
    border-left-width: 0px;
    border-right-color: rgb(255, 255, 255);
    border-right-style: none;
    border-right-width: 0px;
    border-top-color: rgb(255, 255, 255);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-top-style: none;
    border-top-width: 0px;
    box-shadow: rgba(50, 50, 93, 0.11) 0px 4px 6px 0px, rgba(0, 0, 0, 0.08) 0px 1px 3px 0px;
    box-sizing: border-box;
    color: rgb(255, 255, 255);
    cursor: pointer;
    display: block;
    float: left;
    font-family: "Helvetica Neue", Helvetica, sans-serif;
    font-size: 15px;
    font-stretch: 100%;
    font-style: normal;
    font-variant-caps: normal;
    font-variant-east-asian: normal;
    font-variant-ligatures: normal;
    font-variant-numeric: normal;
    font-weight: 600;
    height: 35px;
    letter-spacing: 0.375px;
    line-height: 35px;
    margin-bottom: 0px;
    margin-left: 12px;
    margin-right: 0px;
    margin-top: 28px;
    outline-color: rgb(255, 255, 255);
    outline-style: none;
    outline-width: 0px;
    overflow-x: visible;
    overflow-y: visible;
    padding-bottom: 0px;
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 0px;
    text-align: center;
    text-decoration-color: rgb(255, 255, 255);
    text-decoration-line: none;
    text-decoration-style: solid;
    text-indent: 0px;
    text-rendering: auto;
    text-shadow: none;
    text-size-adjust: 100%;
    text-transform: none;
    transition-delay: 0s;
    transition-duration: 0.15s;
    transition-property: all;
    transition-timing-function: ease;
    white-space: nowrap;
    width: 150.781px;
    word-spacing: 0px;
    writing-mode: horizontal-tb;
    -webkit-appearance: none;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-border-image: none;

}


.form-select {
    /*padding-right: 0 !important;*/
    /*background-image: none !important;*/
}

.form-select .react-select__control {
  border:  none;
  border-radius: 0;
  min-height: auto;
  background: transparent;
}

.form-select .react-select__value-container {
    padding: 0;
}

.form-select .react-select__placeholder {
    margin: 0;
}

.form-select .react-select__placeholder+div {
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 0;
}

.form-select .react-select__indicator-separator {
    display: none;
}

.form-select .react-select__indicators {
    background: #fff;
    position: absolute;
    right: 0;
}

.form-select .react-select__indicator {
    color: #000;
    padding-top: 2px;
    padding-bottom: 0;
}

.form-select .react-select__menu {
    max-height: 300px;
    width: 100%;
    border-radius: .3rem;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15) !important;
}


.form-select .react-select__menu-list::-webkit-scrollbar {
  -webkit-appearance: none;
}

.form-select .react-select__menu-list::-webkit-scrollbar:vertical {
  width: .5rem;
  background: rgba(0, 0, 0, .1);
}

.form-select .react-select__menu-list::-webkit-scrollbar:horizontal {
  height: .5rem;
}

.form-select .react-select__menu-list::-webkit-scrollbar-thumb {
  border-radius: .5rem;
  border: none;
  background-color: rgba(0, 0, 0, .7);
}

.css-1n7v3ny-option:active,
.form-select .react-select__option:hover {
  background: transparent;
  color: #009d63;
  cursor: pointer;
}

.form-select * {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
}